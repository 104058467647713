import APIClient from './APIClient';

export const loadImages = (offset, limit) => {
  return APIClient.get(`/portfolio/load/?offset=${offset}&limit=${limit}`).then(
    (response) =>
      response.data.map((image) => {
        return { ...image, key: image.id, src: image.image };
      })
  );
};
