import React from 'react';
import classes from './Footer.module.css';

const Footer = (props) => {
  return (
    <footer id={classes.footer}>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-4'>
            <h4>Booking & Inquiries</h4>
            <h6>
              <a
                href='mailto:info@rkbeauty.ca'
                className={classes['contact-link']}
              >
                info@rkbeauty.ca
              </a>
              <br />
              <a href='tel:4168317938' className={classes['contact-link']}>
                +1 (416) 831-7938
              </a>
            </h6>

            <div className={classes['social-links']}>
              <a
                target='_blank'
                href='https://www.facebook.com/beautybyrosemua'
                className='facebook'
                rel='noopener noreferrer'
              >
                <i className='bi bi-facebook'></i>
              </a>

              <a
                target='_blank'
                href='https://www.instagram.com/makeupbyrosemua/'
                className='instagram'
                rel='noopener noreferrer'
              >
                <i className='bi bi-instagram'></i>
              </a>
            </div>
          </div>

          <div className='col-lg-7 pe-lg-5 me-lg-5'>
            <h4>Subscribe</h4>
            <p>
              Subscribe to our newsletter in order not miss new looks,
              promotions and discounts
            </p>
            {/* message part on subscribing 
                        <p className="lead"></p>
            */}
            <form>
              <div className='input-group'>
                <input
                  type='text'
                  className={`form-control ${classes['form-control']}`}
                  placeholder='Enter your email'
                  name='email'
                />
                <button
                  className={`btn ${classes['border-rad']}`}
                  type='submit'
                >
                  Subscribe
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
