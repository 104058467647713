import React from 'react';
import { Link } from 'react-router-dom';
import BookAppointment from '../components/book-appointment';
import classes from './Contact.module.css';

const Contact = (props) => {
  return (
    <section id={classes.contact} className={classes.contact}>
      <div className='container'>
        <div className={`section-title ${classes['section-title']}`}>
          <h2>Contact Us</h2>
          <p>
            Do you have an inquiry? Please contact us by phone or email; we'd be
            happy to speak with you.
            <br />
            Here, you may also
            <Link to={'#book-appointment'}> Book an Appointment</Link>
          </p>
        </div>
      </div>

      <div className='map'>
        <iframe
          title='google-map'
          style={{ border: 0, width: '100%', height: '350px' }}
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1476079.2987373436!2d-80.41879102291182!3d43.732004491418685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b8b3c2556cd0bbf%3A0x990011581777d85f!2sKRbeauty!5e0!3m2!1sen!2sca!4v1655614353063!5m2!1sen!2sca'
          allowFullScreen
        ></iframe>
      </div>

      <div className='container mt-5'>
        <div className={classes['info-wrap']}>
          <div className='row'>
            <div className={`col-lg-3 col-md-6 ${classes.info}`}>
              <i className='bi bi-geo-alt'></i>
              <label>Location:</label>
              <p>Major Westney & Ravenscroft Road, Ajax, ON</p>
            </div>

            <div className={`col-lg-3 col-md-6 mt-4 mt-lg-0 ${classes.info}`}>
              <i className='bi bi-clock'></i>
              <label>Open Hours:</label>
              <p>
                Everyday:
                <br />
                6:00 AM - 5:00 PM
              </p>
            </div>

            <div className={`col-lg-3 col-md-6 mt-4 mt-lg-0 ${classes.info}`}>
              <i className='bi bi-envelope'></i>
              <label>Email:</label>
              <p>info@rkbeauty.ca</p>
              <p>katrina@rkbeauty.ca</p>
            </div>

            <div className={`col-lg-3 col-md-6 mt-4 mt-lg-0 ${classes.info}`}>
              <i className='bi bi-phone'></i>
              <label>Call:</label>
              <p>(416) 831-7938</p>
            </div>
          </div>
        </div>

        <BookAppointment />
      </div>
    </section>
  );
};

export default Contact;
