import React, { Fragment, useState, useEffect } from 'react';
import Footer from './Footer';
import Header from './Header';
import TopBar from './TopBar';

const Layout = (props) => {
  const [isScrolled, toggleTopBar] = useState(false);

  useEffect(() => {
    const handleScroll = (event) => {
      const toggle = window.scrollY > 100 ? true : false;
      toggleTopBar(toggle);
    };

    // window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Header />
      <TopBar className={isScrolled ? 'topbar-scrolled' : ''} />
      <div id='main'>{props.children}</div>
      <Footer />
    </>
  );
};

export default Layout;
