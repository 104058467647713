import React from 'react';

import classes from './TopBar.module.css';
import instagramImage from './../../assets/images/instagram-logo.png';
import facebookImage from './../../assets/images/facebook-logo.png';

const TopBar = (props) => {
  return (
    <section
      id={classes.topbar}
      className={`d-flex align-items-center fixed-top ${
        classes[props.className]
      }`}
    >
      <div className='container-fluid container-xl d-flex align-items-center justify-content-center justify-content-between'>
        {/* email and phone */}
        <div className='d-flex'>
          <a
            href='mailto:info@rkbeauty.ca'
            className={`${classes['contact-link']} d-flex align-items-center`}
          >
            <i className='bi bi-envelope'>
              <span>info@rkbeauty.ca</span>
            </i>
          </a>

          <a
            href='tel:4168317938'
            className={`${classes['contact-link']} ms-4 d-none d-lg-flex align-items-center`}
          >
            <i className='bi bi-phone'>
              <span>+1 (416) 831-7938</span>
            </i>
          </a>
        </div>

        {/* social media */}
        <div className='d-flex'>
          <a
            target='_blank'
            href='https://www.instagram.com/makeupbyrosemua/'
            className='instagram'
            rel='noopener noreferrer'
          >
            <img
              src={instagramImage}
              alt=''
              className={classes['social-logo']}
            />
          </a>
          <a
            target='_blank'
            href='https://www.facebook.com/beautybyrosemua'
            className='instagram'
            rel='noopener noreferrer'
          >
            <img
              src={facebookImage}
              alt=''
              className={classes['social-logo']}
            />
          </a>
        </div>
      </div>
    </section>
  );
};

export default TopBar;
