import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';

import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import Portfolio from './pages/Portfolio';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Layout from './components/layout/layout';

function App() {
  const notFoundPageLiteral = (
    <div className='text-center py-5'>
      <h3>Page not found!</h3>
      <Link to={'/'} className='btn btn-secondary'>
        Home
      </Link>
    </div>
  );
  return (
    <>
      <Layout>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/portfolio' element={<Portfolio />} />
          <Route path='/services' element={<Services />} />
          <Route path='/contact' element={<Contact />} />
          <Route path={'*'} element={notFoundPageLiteral} />
        </Routes>
      </Layout>
    </>
  );
}

export default App;
