import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import classes from './Nav.module.css';

const Nav = (props) => {
  const [showMobileNav, toggleMobileNav] = useState(false);

  const toggleNavigation = () => {
    toggleMobileNav((previousState) => !previousState);
  };

  const removeMobileNavigation = () => {
    if (showMobileNav) toggleMobileNav(false);
  };

  const toggleActiveClass = (navData) => {
    return `nav-link scrollto ${navData.isActive ? classes.active : ''}`;
  };

  return (
    <nav
      id={`${classes['navbar']}`}
      className={`${
        classes[showMobileNav ? 'navbar-mobile' : 'navbar']
      } order-last order-lg-0`}
    >
      <ul>
        <li>
          <NavLink
            className={toggleActiveClass}
            to='/'
            onClick={removeMobileNavigation}
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            className={toggleActiveClass}
            to='/about'
            onClick={removeMobileNavigation}
          >
            About
          </NavLink>
        </li>
        <li>
          <NavLink
            className={toggleActiveClass}
            to='/services'
            onClick={removeMobileNavigation}
          >
            Services
          </NavLink>
        </li>
        <li>
          <NavLink
            className={toggleActiveClass}
            to='/portfolio'
            onClick={removeMobileNavigation}
          >
            Portfolio
          </NavLink>
        </li>
        <li>
          <NavLink
            className={toggleActiveClass}
            to='/contact'
            onClick={removeMobileNavigation}
          >
            Contact
          </NavLink>
        </li>
        <li className='mt-3 mt-md-0'>
          <NavLink
            className={`book-a-table-btn ${classes['book-a-table-btn']} scrollto`}
            to='/contact'
            onClick={removeMobileNavigation}
          >
            Book Appointment
          </NavLink>
        </li>
      </ul>
      <i
        className={`bi ${showMobileNav ? 'bi-x' : 'bi-list'} ${
          classes['mobile-nav-toggle']
        } pr-2`}
        onClick={toggleNavigation}
      ></i>
    </nav>
  );
};

export default Nav;
