import React from 'react';
import classes from './Home.module.css';
import Carousel from 'react-bootstrap/Carousel';

import img1 from './../assets/images/slider/md-1.jpeg';
import img2 from './../assets/images/slider/md-2.jpg';
import img3 from './../assets/images/slider/md-3.jpg';
import img4 from './../assets/images/slider/md-4.jpg';
import img5 from './../assets/images/slider/md-5.jpg';
import img6 from './../assets/images/slider/md-6.jpg';
import img7 from './../assets/images/slider/md-7.jpg';
import img9 from './../assets/images/slider/md-9.jpg';
import img10 from './../assets/images/slider/sm-1.jpg';
import img11 from './../assets/images/slider/sm-2.jpeg';
import img12 from './../assets/images/slider/sm-3.jpg';
import img13 from './../assets/images/slider/sm-4.jpg';
import img14 from './../assets/images/slider/sm-5.jpg';
import img15 from './../assets/images/slider/sm-6.jpg';
import img16 from './../assets/images/slider/sm-7.jpg';
import img17 from './../assets/images/slider/sm-8.jpg';

const Home = (props) => {
  const desktopSliderImages = [img3, img1, img7, img2, img4, img5, img9, img6];
  const mobileSliderImages = [
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
  ];

  return (
    <section id={classes.hero}>
      <div className='d-none d-md-block'>
        <Carousel
          interval='3000'
          fade={true}
          controls={false}
          indicators={false}
        >
          {desktopSliderImages.map((img) => {
            return (
              <Carousel.Item className={classes['carousel-item']}>
                <img alt='slide' src={img} className='d-block w-100' />
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>

      <div className='d-md-none d-sm-block'>
        <Carousel
          interval='3000'
          fade={true}
          controls={false}
          indicators={false}
        >
          {mobileSliderImages.map((img) => {
            return (
              <Carousel.Item>
                <img alt='slide' src={img} className='d-block w-100' />
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
    </section>
  );
};

export default Home;
