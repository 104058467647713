import React, { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';

import classes from './book-appointment.module.css';

import { list } from '../services/api/Service';
import { bookAppointment } from '../services/api/Appointment';
import { Link } from 'react-router-dom';

const BookAppointment = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [appointment, updateAppointment] = useState(null);
  const [services, updateServices] = useState([]);
  const [isSubmitting, updateIsSubmitting] = useState(false);
  const [errorStatus, updateErrorStatus] = useState(false);

  const minDate = new Date().toLocaleDateString('en-ca');

  useEffect(() => {
    loadServices();
  }, []);

  const loadServices = useCallback((offset) => {
    list()
      .then((data) => {
        updateServices(data);
      })
      .catch((error) => {
        console.log('some error occurred');
      });
  }, []);

  const formSubmit = (data) => {
    updateIsSubmitting(true);
    updateErrorStatus(false);

    bookAppointment(data)
      .then((data) => {
        updateAppointment({ ...data });
        reset();
        updateIsSubmitting(false);
      })
      .catch((error) => {
        updateAppointment(null);
        updateErrorStatus(true);
      });
  };

  const handleNewAppointment = () => {
    updateAppointment(null);
    updateErrorStatus(false);
    updateIsSubmitting(false);
  };

  if (appointment === null && !errorStatus) {
    return (
      <form
        className={classes['php-email-form']}
        onSubmit={handleSubmit(formSubmit)}
      >
        <fieldset className='row mb-3'>
          <label>Name</label>
          <div className={`col-md-6 form-group ${classes['form-group']}`}>
            <input
              className={`form-control ${errors.client?.name && 'is-invalid'}`}
              placeholder='Full Name'
              {...register('client.name', {
                required: 'Please enter your name',
                minLength: {
                  value: 3,
                  message: 'Please enter a valid name',
                },
              })}
            />
          </div>
          <p className={classes['error-message']}>
            {errors.client?.name?.message}
          </p>
        </fieldset>

        <fieldset className='row mb-3'>
          <label>Email</label>
          <div className={`col-md-6 form-group ${classes['form-group']}`}>
            <input
              type='email'
              className={`form-control ${errors.client?.email && 'is-invalid'}`}
              placeholder='Email Address'
              {...register('client.email', {
                required: 'Please enter your email address',
                pattern: {
                  value: /^\S+@\S+\.\S+$/,
                  message: 'Please provide a valid email address',
                },
              })}
            />
          </div>
          <p className={classes['error-message']}>
            {errors.client?.email?.message}
          </p>
        </fieldset>

        <fieldset className='row mb-3'>
          <label>Phone</label>
          <div className={`col-md-6 form-group ${classes['form-group']}`}>
            <input
              type='tel'
              className={`form-control ${errors.client?.phone && 'is-invalid'}`}
              placeholder='Provide your 9 digit cellphone number'
              {...register('client.phone', {
                required: 'Please enter valid phone number',
                minLength: 10,
                maxLength: 10,
                pattern: /[0-9]{3}[0-9]{3}[0-9]{4}/,
              })}
            />
          </div>
          <p className={classes['error-message']}>
            {errors.client?.phone?.message}
          </p>
        </fieldset>

        <fieldset className='row mb-3'>
          <label>Event Type</label>

          <div className={`col-md-6 form-group ${classes['form-group']}`}>
            <input
              className={`form-control ${errors.eventType && 'is-invalid'}`}
              placeholder='What is your occasion?'
              {...register('event_type', {
                required: 'Please provide your event details',
              })}
            />
          </div>
          <p className={classes['error-message']}>
            {errors.event_type?.message}
          </p>
        </fieldset>

        <fieldset className='row mb-3'>
          <label>Event Date & Ready Time</label>
          <div className={`col-md-6 form-group ${classes['form-group']}`}>
            <input
              type='date'
              min={minDate}
              className={`form-control ${errors.eventDate && 'is-invalid'}`}
              placeholder='date : DD-MM-YYYY'
              {...register('event_date', {
                required: 'Please provide your event date',
              })}
            />
          </div>
          <div className={`col-md-3 input-group ${classes['input-group']}`}>
            <span
              className={`input-group-text ${classes['input-group-text']}`}
              id='basic-addon1'
            >
              @
            </span>
            <input
              type='time'
              className='form-control'
              {...register('event_time')}
            />
          </div>
          <p className={classes['error-message']}>
            {errors.event_date?.message}
          </p>
        </fieldset>

        <fieldset className='row mb-3'>
          <label>On Location, If applicable</label>
          <div className={`col-md-6 form-group ${classes['form-group']}`}>
            <input
              type='text'
              className='form-control'
              placeholder='Address'
              {...register('address')}
            />
          </div>
          <div className={`col-md-4 form-group ${classes['form-group']}`}>
            <input
              type='text'
              className='form-control'
              placeholder='City'
              {...register('city')}
            />
          </div>

          <div className={`col-md-2 form-group ${classes['form-group']}`}>
            <input
              type='text'
              className='form-control'
              placeholder='Postal Code'
              {...register('postal_code')}
            />
          </div>
        </fieldset>

        <fieldset className='row mb-3'>
          <label>Package</label>
          <div className='col-md-6 mt-2'>
            {services.map((service) => {
              return (
                <div className='form-check' key={service.id}>
                  <input
                    type='checkbox'
                    className={`form-check-input ${classes['form-check-input']}`}
                    defaultValue={service.title}
                    {...register('packages[]')}
                  />
                  <label
                    className={`form-check-label ${classes['form-check-label']}`}
                  >
                    {service.title}
                  </label>
                </div>
              );
            })}
            <div className='form-check'>
              <input
                type='checkbox'
                className={`form-check-input ${classes['form-check-input']}`}
                defaultValue='Other'
                {...register('packages[]')}
              />
              <label
                className={`form-check-label ${classes['form-check-label']}`}
              >
                Other
              </label>
            </div>
          </div>
        </fieldset>

        <fieldset className='row mb-3'>
          <label>Specifics</label>
          <div className={`form-group mt-3 ${classes['form-group']}`}>
            <textarea
              className='form-control'
              rows='5'
              placeholder='Tell us about your specification and customize requests'
              {...register('message')}
            ></textarea>
          </div>
        </fieldset>

        <div className='text-center'>
          <button
            type='submit'
            className={` ${classes['submit']} ${
              isSubmitting ? 'activeLoading' : ''
            }`}
          >
            Book Appointment
            <span class='load loading '></span>
          </button>
        </div>
      </form>
    );
  } else {
    return (
      <div className={classes['php-email-form']}>
        <div className='row d-flex justify-content-center'>
          <div className='col-lg-10 box'>
            {!errorStatus && (
              <div>
                <div class='title-success'>
                  <h4>
                    <i class='bi bi-envelope-check'></i> We received your
                    request.
                  </h4>
                </div>
                <p>
                  Dear <strong>{appointment.client?.name}</strong> , we have
                  received your request to book an appointment. our staff will
                  get back to you to confirm your booking with in one business
                  day at your given email address{' '}
                  <strong>{appointment.client?.email}</strong>.
                </p>
              </div>
            )}
            {errorStatus && (
              <div>
                <div class='title-fail'>
                  <h4>
                    <i class='bi bi-envelope-x'></i> We haven't received your
                    request.
                  </h4>
                </div>
                <p>
                  Sorry, we were not able to book your appointment at the
                  moment, kindly try again.
                </p>
              </div>
            )}
            <p>
              If you require any additional information call us at{' '}
              <a href='tel:4168317938' className={classes['contact-link']}>
                <strong>+1 (416) 831-7938</strong>
              </a>
            </p>
            <div class='actions d-flex justify-content-end'>
              <button
                onClick={handleNewAppointment}
                class='btn btn-secondary mx-3 btn-sm mt-4'
              >
                Go Back
              </button>
              <Link to='/' class='btn btn btn-secondary btn-sm mt-4'>
                Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default BookAppointment;
