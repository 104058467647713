import React from 'react';
import { Link } from 'react-router-dom';
import classes from './Header.module.css';
import logo from './../../assets/images/rkbeauty-logo.png';
import Nav from './Nav';
const Header = (props) => {
  return (
    <header
      id={`${classes['header']}`}
      className='fixed-top d-flex align-items-center'
    >
      <div className='container-fluid container-xl d-flex align-items-center justify-content-between'>
        <div className={`${classes.logo} me-auto`}>
          <Link to='/'>
            <img src={logo} alt='' className='img-fluid' />
          </Link>
        </div>

        <Nav />
      </div>
    </header>
  );
};

export default Header;
