import React, { useEffect, useState, useCallback } from 'react';
import PhotoAlbum from 'react-photo-album';

import classes from './Portfolio.module.css';

// import photos from ./photos';
import { loadImages } from '../services/api/Portfolio';

const Portfolio = (props) => {
  const [gallery, updateGallery] = useState([]);
  const [isEnd, updateGalleryEnd] = useState(false);

  useEffect(() => {
    loadMore(0);
  }, []);

  const loadMore = useCallback((offset) => {
    if (!isEnd)
      loadImages(offset, 8)
        .then((data) => {
          if (data.length > 0) {
            updateGallery((previousData) => {
              console.log(previousData);
              console.log(data);
              const x = [...previousData, ...data];
              console.log(x);
              return x;
            });
          } else updateGalleryEnd(true);
        })
        .catch((error) => {});
  }, []);

  const handleLoadMore = () => {
    const offset = gallery.length;
    loadMore(offset);
  };

  return (
    <section id={classes.portfolio} className={classes.portfolio}>
      <div className='container'>
        <div className='section-title'>
          <h2>Our Gallery</h2>
          <p>Check out our new Bridal &amp; Party Looks</p>
        </div>

        <div className='row portfolio-container'>
          <>
            <PhotoAlbum
              photos={gallery}
              layout='masonry'
              columns={(containerWidth) => {
                if (containerWidth < 500) return 1;
                if (containerWidth < 900) return 2;
                if (containerWidth < 1200) return 3;
                return 4;
              }}
            />
          </>
        </div>
        <div className='col-lg-12 pt-5 text-center'>
          {!isEnd && (
            <button
              className={`btn ${classes['btn-load-more']} d-flex`}
              onClick={handleLoadMore}
            >
              Load More Photos
            </button>
          )}
          {isEnd && <h6>No more images to load!</h6>}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
