import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classes from './Services.module.css';

import img1 from './../assets/images/services/gold-bridal-package-2.jpeg';
import img2 from './../assets/images/services/silver-bridal-package.jpeg';
import img3 from './../assets/images/services/party-package.jpg';
import img4 from './../assets/images/services/makeup-only.jpg';
import img5 from './../assets/images/services/hairstyling.jpg';
import img6 from './../assets/images/services/group-package.jpeg';

import { list } from '../services/api/Service';
// import SEO from '../SEO/SEO';

// const Services1 = (props) => {
//   const [services, updateServices] = useState([]);
//   const [columnSize, updateColumnSize] = useState(0);
//   const columns = [0, 1, 2];

//   useEffect(() => {
//     getServiceCategories()
//       .then((services) => {
//         updateServices(services);
//         updateColumnSize(Math.floor(services.length / 3));
//       })
//       .catch((error) => console.log('Something happened!'));
//   }, []);

//   const content = columns.map((column, columnIndex) => {
//     const firstColumn = 0;
//     const start =
//       columnIndex !== firstColumn
//         ? column * columnSize + 1
//         : column * columnSize;
//     const end = column * columnSize + columnSize + 1;

//     const categories = services.slice(start, end);

//     return (
//       <div
//         className='col-lg-4 col-md-7 col-sm-6 row d-flex justify-content-start'
//         key={column}
//       >
//         {categories.map((serviceCategory, index) => {
//           return (
//             <div className='col-lg-12 col-sm-12' key={serviceCategory.id}>
//               <div className={styles['service-item']}>
//                 <img src={serviceCategory.image} alt={serviceCategory.name} />
//                 <div className={styles['service-content']}>
//                   <h5>
//                     <a href='service-details.html'>
//                       {serviceCategory.name} Services
//                     </a>
//                   </h5>
//                   {serviceCategory.require_appointment && (
//                     <small>(Need Appointment)</small>
//                   )}
//                   <Link
//                     to={`/services/${serviceCategory.slug}`}
//                     className={`read-more ${styles['read-more']}`}
//                   >
//                     read more <i className='bi bi-arrow-right'></i>
//                   </Link>
//                   <div className={`widget ${styles['price-list']}`}>
//                     <ul>
//                       {serviceCategory.service_set.map((service, index2) => {
//                         return (
//                           <li key={service.id}>
//                             <div className={styles.title}>{service.name}</div>
//                             <div className={styles['price-dots']}></div>
//                             <div className={styles.price}>{service.price}</div>
//                           </li>
//                         );
//                       })}
//                     </ul>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           );
//         })}
//       </div>
//     );
//   });

//   return (
//     <>
//       <SEO
//         title={'Services -- Beauty Plus Brow Studio'}
//         description={`Beauty Plus Brow Specializing in enhancing natural beauty
//         using semi permanent makeup technique to make you feel
//         confident & look younger, check out list of our services.`}
//         name={'Beauty Plus Brow Studio'}
//         type={'page'}
//       />
//       <section
//         className='page-banner text-white pt-165 pb-130 bg-light-pink'
//         style={{
//           backgroundImage: `url(${pageBanner})`,
//         }}
//       ></section>

//       <section className='pt-80 pb-90 bg-light-pink'>
//         <div className='container'>
//           <div className='row justify-content-center'>
//             <div className='col-lg-10'>
//               <div className='section-title text-center mb-65'>
//                 <img className='bg-image' src={serviceBG} alt='Leaf' />
//                 <span className='sub-title'>What We Offer</span>
//                 <h3>
//                   Beauty Plus Brow Specializing in enhancing natural beauty
//                   using semi permanent makeup technique to make you feel
//                   confident & look younger
//                 </h3>
//               </div>
//             </div>
//           </div>
//           <div className='row justify-content-center'>{content}</div>
//         </div>
//       </section>
//     </>
//   );
// };

const Services = (props) => {
  const [services, updateServices] = useState([]);

  useEffect(() => {
    list()
      .then((services) => {
        updateServices(services);
      })
      .catch((error) => console.log('Something happened!'));
  }, []);

  const servicesContent = services.map((service, index) => {
    if (service.title.includes('(')) {
      const serviceNameArray = service.title.split('(');
      service.title = serviceNameArray[0];
      service.subtitle = '(' + serviceNameArray[1];
    }
    return (
      <div className='col-lg-4 col-md-6' key={index}>
        <div className={classes.member}>
          <div className={classes.pic}>
            <img src={service.image} className='img-fluid' alt='' />
          </div>
          <div className={classes['member-info']}>
            <h3>
              {service.title} <br />
              <small>{` ${service.subtitle}`}</small>
            </h3>
            {service.price !== 0 && (
              <span className={classes.price}>${service.price}</span>
            )}

            {service.price === 0 && <span className={classes.price}>$$</span>}
            {service.discount !== 0 && (
              <small className='discount'>{`${service.discount}% Discount`}</small>
            )}

            <small className={classes['additional-charges']}>
              {service.additional_charges && '(additional charges may apply)'}
            </small>

            <div className='content px-4'>
              <p className='fst-italic'>{service.description}</p>
              <ul>
                {service.details.map((detail, index) => (
                  <li key={index}>
                    <i className='bi bi-check-circle'></i>
                    {detail}
                  </li>
                ))}
                {service.on_location && (
                  <li>
                    <i className='bi bi-bookmark-plus-fill'></i>{' '}
                    {service.on_location}
                  </li>
                )}
                {service.extra_charges && (
                  <li>
                    <i className='bi bi-bookmark-plus-fill'></i>
                    {service.extra_charges}
                  </li>
                )}
                {service.deposit && (
                  <li>
                    <i className='bi bi-bookmark-star-fill'></i>
                    <span className={classes.active}>
                      <strong>Deposit </strong>
                    </span>
                    is required for booking
                  </li>
                )}
              </ul>

              <Link
                className='btn book-a-table-btn scrollto'
                to={'/contact#book-appointment'}
              >
                Book
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <section id={classes.services} className={classes.chefs}>
      <div className={`container ${classes.container}`}>
        <div className={`section-title ${classes['section-title']}`}>
          <h2>Our Bridal & Party Packages</h2>
          <p>
            We offer different packages to suit your Bridal and Party Specifics.{' '}
            <br />
            <span className='active'>
              <strong style={{ fontWeight: '600' }}>
                <a href='mailto:info@rkbeauty.ca'> EMAIL </a>
              </strong>
              or
              <strong style={{ fontWeight: '600' }}>
                <a href='tel:4168317938'> CALL US </a>
              </strong>{' '}
              any time for ENQUIRY
            </span>
          </p>
        </div>

        <div className='row'>{[...servicesContent]}</div>
      </div>
    </section>
  );
};

export default Services;
