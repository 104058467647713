import React from 'react';
import classes from './About.module.css';

import img from './../assets/images/bio/katrina-azimi.jpg';

const About = (props) => {
  return (
    <section id='about' className={`${classes.about} py-4`}>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-3 d-flex justify-content-center px-2'>
            <img src={img} className='img-fluid rounded' alt='Makeup Artist' />
          </div>

          <div className='col-lg-9'>
            <div className={`${classes.content} px-4`}>
              <h3 className='py-4 d-flex justify-content-center justify-content-lg-start'>
                <strong>Katrina Azimi</strong>
              </h3>
              <p>
                KRBeauty Salon, which is based in the wider Toronto area, offers
                a variety of incredibly fashionable styling and cosmetics
                treatments. It was founded in 2012 and is run by
                <span className='primary'>
                  <strong> Katrina Azimi</strong>
                </span>
                , a stylist with extensive training and expertise.
              </p>
              <p className='fst-italic'>
                <span className='primary'>
                  <strong>Katrina Azimi </strong>
                </span>
                is a talented and extremely committed makeup artist. Her love of
                art and creativity is what first ignited her interest in hair
                and makeup. She has been able to develop her portfolio in the
                fields of beauty, fashion, film, and print by pursuing her love.
              </p>
              <p>
                With a focus on bridal hair and makeup, she has acquired the
                ability to comprehend a woman's face and use her understanding
                of beauty to produce works of true art.
              </p>
              <ul>
                <li>
                  <i className='bi bi-check-all'></i> She has more than 10 years
                  of experience in bridal makeup
                </li>
                <li>
                  <i className='bi bi-check-all'></i> She is passionate about
                  perfecting her art
                </li>
                <li>
                  <i className='bi bi-check-all'></i> She has more than 100
                  satisfied and happy clients
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
